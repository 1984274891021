import './bootstrap';
import '../css/app.css';

import * as Sentry from "@sentry/react";

import { createRoot } from 'react-dom/client';
import { createInertiaApp } from '@inertiajs/react';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';

// @ts-ignore
import TagManager from 'react-gtm-module'

const appName = import.meta.env.VITE_APP_NAME || 'Laravel';

const tagManagerArgs = {
    gtmId: 'GTM-MVGHC2G4'
}
 
TagManager.initialize(tagManagerArgs)

//Sentry.init({
//    dsn: "https://7e1c2b60363034430358088d1afdccc6@o4507301133942784.ingest.us.sentry.io/4507301140758528",
//    integrations: [
//      Sentry.browserTracingIntegration(),
//      Sentry.replayIntegration(),
//    ],
//    // Performance Monitoring
//    tracesSampleRate: 1.0, //  Capture 100% of the transactions
//    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//    //tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
//    // Session Replay
//    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
//});

  
createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.tsx`, import.meta.glob('./Pages/**/*.tsx')),
    setup({ el, App, props }) {
        const root = createRoot(el);

        root.render(<App {...props} />);
    },
    progress: {
        color: '#4B5563',
    },
});
